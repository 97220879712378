// material-ui
import { useTheme } from '@mui/material/styles'

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme()
    let logo = 'verv_dark'
    if (import.meta.env.VITE_APP_CLIENT_BRANDING === 'true')
        logo = import.meta.env.VITE_APP_CLIENT_NAME
    console.log(logo)
    return (
        <img src={`/${logo}.png`} alt="Verv Logo" width="100" />
        /**
         * if you want to use SVG instead of img uncomment following, and comment out <img> element.
         *
        <svg width="92" height="32" viewBox="0 0 92 32" fill="none" xmlns="http://www.w3.org/2000/svg">

        </svg>
         *
         */
    )
}

export default Logo
