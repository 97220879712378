import { forwardRef } from 'react'

import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Typography,
} from '@mui/material'
// material-ui
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 },
}

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const theme = useTheme()

        return (
            <Grid item justifyContent="center" xs={12}>
                <Paper ref={ref} elevation={2} {...others}>
                    {content && (
                        <CardContent sx={contentSX} className={contentClass}>
                            {children}
                        </CardContent>
                    )}
                    {!content && children}
                </Paper>
            </Grid>
        )
    }
)

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
}

export default MainCard
